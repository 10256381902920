import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from '../global/boton/ButtonContent'
// import { BiPhoneCall, BiCalendar } from 'react-icons/bi'
import CounterUp from '../global/CounterUp'
import Map from "../Contact/MapContent";

function ServicesArea() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.works?.map((item, index) => {
                    if (item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase() === id) {
                        return (
                          <div
                            key={index}
                            className="bg-[url('https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/imagenes_fondos%2Fsilueta%20united%20state.png?alt=media&token=3454fcd5-b7b9-40e9-8f99-bb9ac550a0cc')] relative bg-fixed bg-no-repeat bg-cover bg-center bg-blend-multiply bg-opacity-50"
                          >
                            <div class="absolute w-full h-full z-1 top-0 left-0 bg-gray-600/30" />
                            <TransparentHeader
                              // imagen aleatoria
                              bgimg={
                                rpdata?.gallery?.length > 1
                                  ? rpdata?.gallery[
                                      Math.floor(
                                        Math.random() * rpdata?.gallery?.length
                                      )
                                    ]
                                  : rpdata?.stock[
                                      Math.floor(
                                        Math.random() * rpdata?.stock?.length
                                      )
                                    ]
                              }
                              headertitle={item.name}
                              Subheader={"Services Area"}
                            />
                            <main class="relative z-20 w-full my-8 md:flex md:items-center xl:mt-12">
                              <div class="absolute w-full bg-[#0d65ad] -z-10 md:h-96 rounded-2xl"></div>

                              <div class="w-full p-6 bg-[#0d65ad] md:flex md:items-center rounded-2xl md:bg-transparent md:p-0 lg:px-12 md:justify-evenly">
                                {item.gallery.map((item, index) => {
                                  return (
                                    <img
                                      className="h-4/5 w-3/4 md:mx-6 rounded object-cover shadow-md md:h-[32rem] md:w-80 lg:h-[36rem] lg:w-[26rem] md:rounded-2xl"
                                      key={index}
                                      src={item}
                                      alt={item}
                                    />
                                  );
                                })}

                                <div class="mt-2 md:mx-6">
                                  <div>
                                    <h2 class="font-medium tracking-tight text-white">
                                      {item.name}
                                    </h2>
                                  </div>

                                  <p class="mt-4 text-lg leading-relaxed text-white md:text-xl">
                                    {item.description}
                                  </p>

                                  <div class="flex items-center justify-between mt-6 md:justify-start">
                                    <ButtonContent btnName={"contact us"}  btnStyle="six" />
                                  </div>
                                </div>
                              </div>
                            </main>
                          </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }

            <CounterUp image={rpdata?.gallery?.[6]} />

            <Map />

        </BaseLayout>
    );
}

export default ServicesArea;